/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";

import { AppContext } from "~context/AppContext";

import Banner from "~components/Banner";
import KlaviyoForm from "~components/KlaviyoForm";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class MakeunderPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setMenuActive(false);
    appContext.setNavText(`We believe in made up, not made up.`);
  }

  //

  render() {
    const { bannerMedia } = this.props.data.markdownRemark.frontmatter;
    // console.log(bannerMedia);

    return (
      <>
        <SEO pageTitle="Makeunder" pathName="/makeunder" />

        <Layout className="makeunder-page w-full bg-sun-yellow gpu">
          {bannerMedia && bannerMedia.src && bannerMedia.bannerText && (
            <Banner
              bannerImage={bannerMedia.src.childImageSharp.fluid}
              bannerText={bannerMedia.bannerText}
            />
          )}

          <KlaviyoForm className="pt-4 pb-32" klaviyoId="JGGgbS" makeunder>
            <section className="grid-end-10 xs:grid-end-11 mb-8">
              <article
                className="animation-appear"
                style={{ animationDelay: `50ms` }}
              >
                <h2
                  className={`animation-appear makeunder-page__title ${
                    this.props.appContext.device === `mobile` ? `f2` : `d2`
                  } font-medium`}
                >
                  Want to still look like you when you get a Makeover?
                </h2>
              </article>

              <article
                className="animation-appear grid-end-8 xs:grid-end-11"
                style={{ animationDelay: `100ms` }}
              >
                <h1 className="makeunder-page__title mt-2 d2 font-medium">
                  Book a Makeunder®
                </h1>
              </article>
            </section>
          </KlaviyoForm>
        </Layout>
      </>
    );
  }
}

const MakeunderPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <MakeunderPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default MakeunderPage;

export const query = graphql`
  query MakeunderPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
  }
`;
